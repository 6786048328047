import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { byParent, cap, copyToClip, slugify } from './utils'

class Out {
    constructor(data) {
        return [
            { name: "EasySend is...", iter: data.highLevelIs, expand: false },
            { name: "Company Types", iter: data.companyType, groupByParent: true, expand: false },
            { name: "Functions", iter: data.func, groupByParent: true, expand: false },
            { name: "Customer Pains", iter: data.problem, expand: false },
            { name: "Business Impact", iter: data.pain, expand: false },
            { name: "Supporting Data & Stats", iter: data.problemImpact, expand: false },
            { name: "Use Cases", iter: data.useCase, groupByParent: true, expand: false },
            { name: "Capabilities", iter: data.capability, expand: false },
            { name: "Benefits", iter: data.benefit, expand: false },
            { name: "KPIs", iter: data.kpi, groupByParent: true, expand: false },
            { name: "Dealbreaker Needs", iter: data.dealbreakerNeed, expand: false },
            { name: "Reference Customers", iter: data.customer, groupByRelevantFor: "companyType", expand: false },
        ]
            .filter(s => s.iter.filter(i => i.shortDefinition || i.longDefinition).length > 0)
            .map(s => ({ ...s, iter: s.iter.filter(i => i.children.length > 0 || i.shortDefinition || i.longDefinition) }))
    }
}

export default function KnowledgeBase({ data }) {
    const [out, setOut] = useState(data.loaded ? new Out(data) : []);

    useEffect(() => {
        if (data.loaded) setOut(new Out(data))
    }, [data.loaded]);

    if (!data.loaded) return null;

    function Item({ item, children, parentSections }) {
        return (
            <div key={item.id} id={item.slug} className="kb-item">
                <br />
                <b className="text-muted">{parentSections && parentSections.join(" > ") + " > "}</b>
                <h4>{cap(item.text)}</h4>
                <div style={{ paddingLeft: "20px", borderLeft: "3px #333 solid" }}>
                    <ReactMarkdown children={item.shortDefinition} />
                    <div class="long-definition">
                        <ReactMarkdown children={item.longDefinition} />
                    </div>
                </div>

                {children && <div style={{ paddingLeft: "20px" }}>
                    {children.map(child => <Item key={child.id} item={child} parentSections={[...parentSections, cap(item.text)]} />)}
                </div>}
            </div>
        )
    }

    function Section({ section }) {
        if (section.groupByParent) {
            let subsections = byParent(section.iter);
            return <div id={slugify(section.name)} className="kb-section">
                <h3>{section.name}</h3>
                {Object.entries(subsections).map(([parent, iter]) => data.byId[parent] ?
                    <Item key={parent} item={data.byId[parent]} children={iter} parentSections={[section.name]} /> : null)}
            </div>
        }

        return (
            <div key={section.id} id={slugify(section.name)} className="kb-section">
                <h3>{section.name}</h3>
                {section.iter ? section.iter.map(item => <Item key={item.id} parentSections={[section.name]} item={item} />) : null}
            </div>
        )
    }

    function TableOfContentsItem({ item, children }) {
        return <li>
            <a href={"#" + item.slug} className="link-light">{cap(item.text)}</a>
            {children && <div style={{ paddingLeft: "20px" }}>
                {children.map(child => <TableOfContentsItem key={child.id} item={child} />)}
            </div>}
        </li>
    }

    function TableOfContentsSection({ section }) {
        if (section.groupByParent) {
            let subsections = byParent(section.iter);
            return <li>
                <a key={section.name} href={`#${slugify(section.name)}`}>{section.name}</a>
                <ul>
                    {Object.entries(subsections).map(([parent, iter]) => data.byId[parent] ?
                        <TableOfContentsItem key={parent} item={data.byId[parent]} children={iter} /> : null)}
                </ul>
            </li>
        }

        //     <div id={slugify(section.name)} className="kb-section">
        //         <h3>{section.name}</h3>
        //         {Object.entries(subsections).map(([parent, iter]) => data.byId[parent] ?
        //             <Item key={parent} item={data.byId[parent]} children={iter} /> : null)}
        //     </div>
        // }

        return (
            <li>
                <a key={section.name} href={`#${slugify(section.name)}`}>{section.name}</a>
                <ul>
                    {section.iter ? section.iter.map(item => <TableOfContentsItem key={item.id} item={item} />) : null}
                </ul>
            </li>
        )
    }

    function TableOfContents({ sections }) {
        return <div className="card">
            <div className="card-header">
                <h3>Table of Contents</h3>
            </div>
            <div className="card-body text-light link-light">
                {sections.map(section => <ul><TableOfContentsSection key={section.name} section={section} /></ul>)}
            </div>
        </div>
    }

    return <>
        <h2>Knowledge Base</h2>
        <br />
        <TableOfContents sections={out} />
        <br />
        {out.map(section => <Section key={section.name} section={section} />)}
    </>
}
