import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { cap } from './utils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from 'react-markdown'

export default function Selector({ message, setMessage }) {
    const navigate = useNavigate();
    const [kbItem, setKbItem] = useState(null);

    function startOver() {
        setMessage({ ready: false });
        navigate("/");
    }

    function KBModal(props) {
        return kbItem && (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="text-light"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {kbItem && cap(kbItem.text)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "80vh", overflow: "scroll" }}>
                    {kbItem.shortDefinition}
                    {kbItem.shortDefinition && <hr />}
                    {kbItem.longDefinition && <ReactMarkdown children={kbItem.longDefinition} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setKbItem(null)}>Got it</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    if (!message.ready) return null;
    return <div style={{ position: "absolute", left: "355px", right: "60px", verticalAlign: "middle" }}>
        <div style={{ float: "right" }}>
            <button className="btn" onClick={() => startOver()}>Start Over</button>
        </div>
        <h3>Messaging for <a href="javascript://"
            onClick={() => setKbItem(message.func)}
            className="link-light">
            <OverlayTrigger placement={'bottom'} overlay={
                <Tooltip>{message.func.shortDefinition || (message.func.longDefinition.substr(0, 50) + " ...")}</Tooltip>
            }><span>{message.func.text}</span>
            </OverlayTrigger></a>
            &nbsp;at <a href="javascript://"
                onClick={() => setKbItem(message.companyType)}
                className="link-light">
                <OverlayTrigger placement={'bottom'} overlay={
                    <Tooltip>{message.companyType.shortDefinition || (message.companyType.longDefinition.substr(0, 50) + " ...")}</Tooltip>
                }><span>{message.companyType.text}</span>
                </OverlayTrigger>
            </a></h3>
        <KBModal
            show={kbItem}
            onHide={() => setKbItem(null)}
        />

    </div>
}
