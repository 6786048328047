import React, { useState } from 'react'
import { cap, copyToClip } from './utils'

export default function PitchGenerator({ message, data }) {
    const [prompt, setPrompt] = useState({ requested: false, loading: false, text: "", paragraphs: [] });

    const out = [
        message.problem,
        message.pain,
        message.problemImpact,
        message.useCase,
        message.capability,
        message.benefit,
        message.kpi,
        message.dealbreakerNeed,
        data.highLevelIs,
        message.customer,
    ];

    function getPrompt() {
        setPrompt({ requested: true, loading: true, text: "", paragraphs: [] });
        let req = out.reduce((acc, section) => {
            var section = section.filter(item => item.show);
            if (section.length > 0) acc[section[0].type] = section.map(item => cap(item.text));
            return acc;
        }, {});

        req.intro = "EasySend is the perfect solution for";
        req.func = message.func.text;
        req.companyType = message.companyType.text;

        return fetch("https://easysend.message.inc/swbhcktw/pptmpl/prompt", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(req)
        })
            .then(res => res.json())
            .then(res => {
                let paragraphs = res.response.replaceAll(". ", ".\n").split("\n");
                setPrompt({ requested: true, text: res.response, paragraphs: paragraphs });
            });
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>The Pitch
                    <div style={{ float: "right" }}>
                        <button className="btn btn-large" onClick={() => copyToClip("pitch")}>Copy Pitch</button>
                        &nbsp;
                        <button className="btn btn-large" onClick={() => getPrompt()}>Engage! 👉</button>
                    </div>
                </h3>
                {/* <h6>Global challenges a customer is facing that causes pain points, that our product can address</h6> */}
            </div>
            <div className="card-body text-light align-middle" style={{ minHeight: "calc( 100vh - 240px )", textAlign: prompt.loading ? "center" : "left", verticalAlign: "middle" }}>
                {!prompt.requested && !prompt.loading && <img src="/tng.png" style={{ width: "100%", marginTop: "100px" }} />}
                {prompt.loading && <div style={{ marginTop: "30vh" }} className="spinner-border text-light" role="status"></div>}
                {prompt.paragraphs.map(paragraph => <p style={{ fontSize: "1.5em" }}>{paragraph}</p>)}
            </div>
            <div id="pitch" style={{ display: "none" }}>
                {prompt.paragraphs.map(paragraph => <p>{paragraph}</p>)}
            </div>
        </div>
    )
}
