export function cap(str) {
    return str[0].toUpperCase() + str.slice(1);
}

export function copyToClip(id) {
    let str = document.getElementById(id).innerHTML;
    function listener(e) {
        e.clipboardData.setData("text/html", str);
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
};

export function slugify(str) {
    return str
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}

export function byParent(iter) {
    return iter
        .map(item => [item, (item.parent || { id: "Uncategorized" }).id])
        .reduce((acc, [item, parent]) => ({ ...acc, [parent]: [...(acc[parent] || []), item] }), {});
}

export function resources(item) {
    return (item.type === "resource") ? [item] : item.relevantItems.filter(item => item.type === "resource");
}

export function resourceCap(resource) {
    const [verb, type] = resource.parent.text.split("__");
    return "[" + cap(verb) + " " + cap(type) + "] " + cap(resource.text);
}
