import PdfMerger from "./PdfMerger";
import React, { useState, useEffect } from "react";
import Selector from "./Selector";
import Template from "./Template2";
import Container from 'react-bootstrap/Container';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Menu from "./Menu";
import PitchGenerator from "./PitchGenerator";
import PptxGenerator from "./PptxGenerator";
import KnowledgeBase from "./KnowledgeBase";
import Wizard from "./Wizard";
import { Compass } from "react-bootstrap-icons";
import { slugify } from "./utils";
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { gapi } from 'gapi-script';
import "./App.css";
import TagManager from 'react-gtm-module'
import jwt_decode from "jwt-decode";

const tagManagerArgs = {
  gtmId: 'GTM-WMN5LTT'
}

TagManager.initialize(tagManagerArgs)

const DB_ROOT = "https://easysend.message.inc/swbhcktw/db/api";
const FILE_ROOT = [DB_ROOT, "files/data"].join("/");

const MESSAGE_TYPES = [
  'problem',
  'pain',
  'problemImpact',
  'useCase',
  'capability',
  'benefit',
  'dealbreakerNeed',
  'highLevelIs',
  'customer',
  'kpi',
  'resource'
];


export class Message {
  constructor(data, companyTypeId, funcId) {
    this.ready = false;
    if (!(data.loaded && companyTypeId && funcId)) return;
    this.companyType = data.companyTypeById[companyTypeId];
    this.func = data.funcById[funcId];

    MESSAGE_TYPES.forEach(type => this[type] = []);

    let foundResources = [];

    this.companyType.relevantItems
      .filter(item => {
        return MESSAGE_TYPES.includes(item.type) && this.func.relevantItems.map(item => item.id).includes(item.id)
      })
      .forEach(item => {
        item.show = true;
        this[item.type].push(item);
        foundResources = [...foundResources, ...item.relevantItems.filter(item => item.type === "resource")];
      });

    this.resource = this.resource.filter(item => !foundResources.includes(item));
    this.ready = true;
  }
}

function App() {
  function Header() {
    return <Navbar expand="lg" variant="dark" bg="dark" sticky="top" className="shadow">
      <Container fluid>
        <Navbar.Brand href="/" className="ms-2">
          <img src="/logo-dark.svg" height="50" />
        </Navbar.Brand>
        <Selector message={message} setMessage={setMsg} />
      </Container>
    </Navbar>;
  }

  const [profile, setProfile] = useState(null);
  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: ''
  //     });
  //   };
  //   gapi.load('client:auth2', initClient);
  // });

  const onSuccess = (res) => {
    console.log(res);
    var decoded = jwt_decode(res.credential);
    console.log(decoded);
    if (!(decoded.email.endsWith("@easysend.io") || ["michael@leandigo.com", "lisa.wardlaw@gmail.com", "gisselle.ivan@gmail.com", "aviv.c@datarails.com"].includes(decoded.email))) return alert("Your domain is not authorized to use this tool.");
    setProfile({
      name: decoded.name,
      email: decoded.email,
    });
  };

  const onFailure = (err) => {
    console.log('failed', err);
  };

  const logOut = () => {
    googleLogout();
    setMessage({ ready: false });
    setProfile(null);
    navigate("/");
  };

  const { page, companyType, func } = useParams();
  const [data, setData] = useState({ requested: false });
  const [message, setMessage] = useState(new Message(data, companyType, func));
  const navigate = useNavigate();

  if (profile === null) return (
    <div className="app text-bg-dark">
      <Header />
      <div className="login">
        <div class="row">
          <div class="col-md-12">
            <h2>Welcome to <b>message<i style={{ color: "purple" }}>.inc</i></b></h2>
            <br />
            <br />
            <span style={{ margin: "auto", textAlign: "center" }}>
              {profile ? (
                <div>
                  <h3>User Logged in</h3>
                  <p>Name: {profile.name}</p>
                  <p>Email Address: {profile.email}</p>
                  <br />
                  <br />
                  {/* <GoogleLogout clientId={clientId} buttonText="Log out" onLogoutSuccess={logOut} /> */}
                </div>
              ) : (<>

                <GoogleLogin
                  onSuccess={onSuccess}
                  onError={() => {
                    console.log('Login Failed')
                  }}
                />
              </>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  function setMsg(msg) {
    setMessage(msg);
    navigate("/" + [page, companyType, func].join("/"));
  }

  // if (data.loaded && (page !== "wizard" || !page || !companyType || !func)) {
  //   return navigate("/wizard");
  // }

  if (data.loaded && !page) {
    return navigate("/wizard");
  }

  if (data.loaded && page !== "wizard" && (!message.ready || message.companyType.id !== companyType || message.func.id !== func))
    return setMessage(new Message(data, companyType, func));

  if (!data.requested) {
    fetch([DB_ROOT, "collections/data/records?perPage=1000"].join("/"))
      .then((res) => res.json())
      .then((res) => {
        if (data.items) return;
        let ret = res.items.reduce((acc, item) => {

          const type = item.type === "function" ? "func" : item.type;
          const byId = type + "ById";

          // Check if a byId category exists for type, if not create it
          acc[byId] = acc[byId] || { root: { children: [] } };

          item.show = true;
          item.slug = slugify(item.type + "-" + item.text);

          // Set the children of the stub item to the children of the current item
          item.children = (acc.byId[item.id] || {}).children || [];

          // Set the relevant items of the stub item to the relevant items of the current item
          item.relevantItems = (acc.byId[item.id] || {}).relevantItems || [];

          acc.byId[item.id] = item;
          acc[type] = acc[type] || [];
          acc[type].push(item);

          // Find all items that are relevant for the current item, and add the current item to their relevantItems
          item.relevantFor.forEach(parentId => {
            if (!acc.byId[parentId]) acc.byId[parentId] = { children: [], relevantItems: [] };
            acc.byId[parentId].relevantItems.push(item);
          });

          // Add the current item the children of its parent
          if (item.parent) {

            // If the parent doesn't exist, create a stub item for the parent
            if (!acc.byId[item.parent]) acc.byId[item.parent] = { children: [], relevantItems: [] };

            // Add the current item to the (stub or actual) parent's children
            acc.byId[item.parent].children.push(item);
          } else {

            // If the current item has no parent, add it to the root's children
            acc[byId].root.children.push(item);
          }

          acc[byId][item.id] = item;

          return acc;
        }, {
          byId: {},
          requested: true,
          loaded: true,
        });

        ret.images = {};
        Object.values(ret.byId).forEach(item => {
          item.parent = item.parent ? ret.byId[item.parent] : null;
          if (item.type == "resource" && (item.parent && item.parent.text == "image")) {
            ret.images[item.text] = item;
            item.src = [FILE_ROOT, item.id, item.file].join("/");
          }
        });
        Object.values(ret.byId).forEach(item => {
          if (item.longDefinition) item.longDefinition = item.longDefinition.split("\n").reduce((acc, line) => {
            if (line.startsWith("$image$") && line.endsWith("$")) {
              const [_none, _, id] = line.split("$");
              line = "![" + id + "](" + ret.images[id].src + ")";
            }
            return [...acc, line];
          }, []).join("\n");
        });
        setData(ret);
      });

    // fetch("http://192.168.0.24:8090/api/collections/resources/records?perPage=1000")
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (!data.resources) setData({ ...data, resources: res.items })
    //   });

    setData({ ...data, requested: true, generate: 0, show: false });
  }

  return (
    <>
      <div className="app text-bg-dark">
        <Header />
        <div className="body d-flex flex-nowrap">
          <Menu logOut={logOut} />
          {data.loaded && page == "wizard" && <Container className="mt-4 d-flex flex-column flex-shrink-0 p-3 bg-dark" style={{ marginLeft: "100px" }}>
            <Wizard data={data} style={{ left: "-200px !important" }} />
          </Container>}
          <Container className="mt-4 d-flex flex-column flex-shrink-0 p-3 bg-dark" style={{ marginLeft: "340px", maxWidth: "calc( 100% - 380px )" }}>
            {message.ready && page == "message" && message.ready && <Template data={data} message={message} setMessage={setMsg} />}
            {message.ready && page == "pitch" && <PitchGenerator message={message} data={data} />}
            {/* <PptxGenerator /> */}
            {page == "kb" && <KnowledgeBase data={data} />}
          </Container>
        </div>
      </div>
    </>
  )
}

export default App;
