import React from 'react'
import { NavLink, useParams } from 'react-router-dom';
import { Book, ChatLeftDots, FileEarmarkEasel, ListStars, Google } from 'react-bootstrap-icons';
import { GoogleLogout } from '@react-oauth/google';

export default function Menu({ clientId, logOut }) {
    const { page, companyType, func } = useParams();

    return (companyType && func &&
        <div className="d-flex flex-column flex-shrink-0 p-3" style={{ backgroundColor: "#333344", width: "280px", position: "fixed", height: "calc(100vh - 70px)" }}>
            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <NavLink to={"/message/" + [companyType, func].join("/")} className="nav-link text-white">
                        <ListStars />&nbsp;
                        Customize Message
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/pitch/" + [companyType, func].join("/")} className="nav-link text-white">
                        <ChatLeftDots />&nbsp;
                        Generate Pitch
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to={"/pptx/" + [companyType, func].join("/")} className="nav-link text-white">
                        <FileEarmarkEasel />&nbsp;
                        Generate PPT
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to={"/kb/" + [companyType, func].join("/")} className="nav-link text-white">
                        <Book />&nbsp;
                        Knowledge Base
                    </NavLink>
                </li>
            </ul>
            <span className="google" style={{ textAlign: "center" }}>
                <button style={{ padding: "5px 15px 10px 15px" }} onClick={logOut} ><Google /> <span style={{ fontSize: "0.8em" }}>Sign out</span></button>
            </span>
        </div>
    )
}
