import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { cap } from './utils'

export default function Wizard({ data }) {
    const [selection, setSelection] = useState({ companyType: null, func: null });
    const navigate = useNavigate()
    useEffect(() => {
        selection.companyType && selection.func && navigate(`/message/${selection.companyType.id}/${selection.func.id}`)
    }, [selection]);

    return (
        <div className="wizard" style={{ height: "calc( 100vh - 90px )", paddingTop: "100px", width: "100%" }}>
            <h2>Hello!</h2>
            <h2>Who are we contacting today? <i>{cap((selection.companyType || selection.func || { text: " " }).text)}</i></h2>
            <h2>
                {selection.companyType && <span>What's their function?</span>}
            </h2>
            <h2>
                {selection.func && <span>At what kind of company?</span>}
            </h2>
            <div className="row">
                {!selection.companyType && <div className="card inline" style={{ width: "46.5%" }}>
                    <div className="card-header"><h4>Company Type</h4></div>
                    <ul className="list-group list-group-flush">
                        <li onClick={() => setSelection({ ...selection, companyType: data.companyTypeById.root.children[0] })} className="list-group-item text-light">{cap(data.companyTypeById.root.children[0].text)} <i className="text-muted">(Not sure / general message)</i></li>
                        {((selection.func && selection.func !== data.funcById.root.children[0]) ? selection.func.relevantFor.map(id => data.byId[id]).filter(i => i.type === "companyType") : data.companyTypeById.root.children[0].children.filter(i => i.relevantItems.filter(r => r.type == "function").length)).map((item, index) =>
                            <li onClick={() => setSelection({ ...selection, companyType: item })} className="list-group-item  text-light" key={index}>{cap(item.text)}</li>
                        )}
                    </ul>
                </div>}
                {!selection.func && <div className="card inline" style={{ width: "46.5%" }}>
                    <div className="card-header"><h4>Function</h4></div>
                    <ul className="list-group list-group-flush">
                        <li onClick={() => setSelection({ ...selection, func: data.funcById.root.children[0] })} className="list-group-item text-light">{cap(data.funcById.root.children[0].text)} <i className="text-muted">(Not sure / general message)</i></li>
                        {((selection.companyType && selection.companyType !== data.companyTypeById.root.children[0]) ? selection.companyType.relevantItems.filter(i => i.type === "function") : data.funcById.root.children[0].children).map((item, index) =>
                            <li onClick={() => setSelection({ ...selection, func: item })} className="list-group-item  text-light" key={index}>{cap(item.text)}</li>
                        )}
                    </ul>
                </div>}
            </div>
            {(selection.func || selection.companyType) && <button onClick={() => setSelection({ companyType: null, func: null })} className="btn">Start Over</button>}
        </div>
    )
}
