import React, { useState, useEffect } from 'react'
import { ChatLeftText, FileEasel, GraphUpArrow, JournalText, Newspaper, PlayBtn, QuestionSquare, X } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import PdfMerger from './PdfMerger';
import { byParent, cap, copyToClip, resources, resourceCap } from './utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from 'react-markdown'


const FILE_ROOT = "http://localhost:8090/api/files/resources/";
const SLIDE_ROOT = "http://localhost:8090/api/files/dataPoints/";
export default function Template({ data, message, setMessage }) {

    const [kbItem, setKbItem] = useState(null);
    const [clipModal, setClipModal] = useState(false);

    useEffect(() => {
        clipModal && copyToClip("message");
    }, [clipModal]);

    function remove(item) {
        setMessage({
            ...message,
            [item.type]: message[item.type].map(i => ({ ...i, show: i.id === item.id ? false : i.show }))
        })
    }

    // function traverseParents(item) {
    //     let ret = [];
    //     while (item) {
    //         ret.push(item);
    //         item = item.parent;
    //     }
    //     return ret.reverse();
    // }

    const out = [
        { name: "Customer Pains", iter: message.problem },
        { name: "Business Impact", iter: message.pain },
        { name: "Supporting Data & Stats", iter: message.problemImpact },
        { name: "Use Cases", iter: message.useCase, groupByParent: true },
        { name: "Capabilities", iter: message.capability },
        { name: "Benefits", iter: message.benefit },
        { name: "KPIs", iter: message.kpi, groupByParent: true },
        { name: "Dealbreaker Needs", iter: message.dealbreakerNeed },
        { name: "EasySend Is...", iter: data.highLevelIs },
        { name: "Reference Customers", iter: message.customer },
        { name: "Additional Resources", iter: message.resource },
    ];

    const resourceIcon = {
        "get__ebook": <JournalText />,
        "read__case study": <GraphUpArrow />,
        "watch__video": <PlayBtn />,
        "read__blog": <ChatLeftText />,
        "read__press release": <Newspaper />,
        "download__slide deck": <FileEasel />,
    }

    function KBModal(props) {
        return kbItem && (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="text-light"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {kbItem && cap(kbItem.text)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "80vh", overflow: "scroll" }}>
                    {kbItem.shortDefinition}
                    {kbItem.shortDefinition && <hr />}
                    {kbItem.longDefinition && <ReactMarkdown children={kbItem.longDefinition} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setKbItem(null)}>Got it</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function ItemListInner({ iter, clipboard }) {
        if (clipboard) return <ul>{iter.filter(item => item.show).map(item => <li key={item.id}>
            {item.type === "resource" ? <a href={item.url} className="link-light" target="_blank">{resourceCap(resources(item)[0])}</a> : <span>
                {cap(item.text)}
                {resources(item).map((r, ix) => <span key={ix}>
                    {ix === 0 ? " (" : " | "}
                    <a href={r.url} target="_blank" className="link-light">{resourceCap(r).split("[")[1].split("]")[0]}</a>
                    {ix === resources(item).length - 1 ? ")" : ""}
                </span>)}
            </span>}
        </li>)}</ul>

        return <ul className="list-group list-group-flush">
            {iter.filter(item => item.show).map(item => <li className="list-group-item text-light" key={item.id}>
                <div style={{ float: "left" }}>
                    <a href="javascript://"
                        className="link-light"
                        onClick={() => remove(item)}
                        style={{ textDecoration: "none", verticalAlign: "top" }}><X /></a>&nbsp;&nbsp;
                </div>
                <div style={{ float: "right" }}>
                    &nbsp;&nbsp;
                    {resources(item).map(r => <OverlayTrigger key={r.id} placement={'top'} overlay={<Tooltip>{resourceCap(r)}</Tooltip>}>
                        <a href={r.url} target="_blank" rel="noreferrer"><span className="badge bg-secondary">{resourceIcon[r.parent.text]}</span></a>
                    </OverlayTrigger>)}
                    {(item.shortDefinition || item.longDefinition) && <>&nbsp;&nbsp;
                        <a href="javascript://"
                            className="link-light"
                            style={{ textDecoration: "none", verticalAlign: "top" }}><OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip>{item.shortDefinition || (item.longDefinition.substr(0, 50) + " ...")}</Tooltip>
                                }
                            ><QuestionSquare onClick={() => setKbItem(item)} />
                            </OverlayTrigger></a></>}

                </div>
                {cap(item.text)}
            </li>)}
        </ul>
    }

    function ItemList({ iter, groupByParent, clipboard }) {
        if (!groupByParent) return <ItemListInner iter={iter} clipboard={clipboard} />
        const iterByParent = byParent(iter);

        if (clipboard) return <ul>{Object.keys(iterByParent).map(parent => <li key={parent}>
            {cap((data.byId[parent] || { text: "Uncategorized" }).text)}:
            <ItemListInner iter={iterByParent[parent]} clipboard={clipboard} />
        </li>)}</ul>

        return <div className="row">
            {Object.keys(iterByParent).map(parent =>
                <div className="card inline" style={{ width: "46.5%" }} key={parent}>
                    {parent === "Uncategorized" ? <div className="card-header"><h6>Uncategorized</h6></div> :
                        <div className="card-header">
                            {(data.byId[parent].shortDefinition || data.byId[parent].longDefinition) && <div style={{ float: "right" }}>
                                <a href="javascript://"
                                    className="link-light"
                                    style={{ textDecoration: "none", verticalAlign: "top" }}><OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>{data.byId[parent].shortDefinition || (data.byId[parent].longDefinition.substr(0, 50) + " ...")}</Tooltip>
                                        }
                                    ><QuestionSquare onClick={() => setKbItem(data.byId[parent])} />
                                    </OverlayTrigger></a>

                            </div>}
                            <h6>{cap(data.byId[parent].text)}</h6>
                        </div>}
                    <ItemListInner iter={iterByParent[parent]} clipboard={clipboard} />
                </div>)}
        </div>
    }

    return (
        <>
            <h3>
                <div style={{ float: "right" }}>
                    <button className="btn btn-large" onClick={() => setClipModal(true)}>Copy Message</button>
                </div>
            </h3>
            <div className="text-light">
                {out.map(type => <div className="card" key={type.name}>
                    <div className="card-header">
                        <h4>{type.name}</h4>
                        {/* <h6>Global challenges a customer is facing that causes pain points, that our product can address</h6> */}
                    </div>
                    <ItemList iter={type.iter} groupByParent={type.groupByParent} />
                </div>)}
                <KBModal
                    show={kbItem}
                    onHide={() => setKbItem(null)}
                />

            </div>

            <Modal
                show={clipModal}
                onHide={() => setClipModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="text-light"
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        The following text has been copied to your clipboard:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "80vh", overflow: "scroll" }}>
                    <div id="message">
                        <h3>When talking to <em>{message.func.text}</em> at <em>{message.companyType.text}</em></h3>
                        <div>
                            {out.filter(type => type.iter.filter(item => item.show).length).map(type => <div key={type.name}>
                                <h4>{type.name}</h4>
                                <ItemList iter={type.iter} groupByParent={type.groupByParent} clipboard={true} />
                            </div>)}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setClipModal(false)}>Got it</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}